<template>
  <g fill="none" fill-rule="evenodd">
    <g stroke="#79838E" stroke-width="1.667">
      <g>
        <g>
          <path
            d="M2.5 3.611l1.042 16.667c.05.963.75 1.666 1.666 1.666h9.584c.92 0 1.607-.703 1.666-1.666L17.5 3.61M.833 3.611L19.167 3.611"
            transform="translate(-833 -686) translate(190 616) translate(643 71)"
          />
          <path
            d="M6.667 3.611V1.528c0-.165.031-.327.094-.48.062-.151.155-.29.27-.405.117-.116.255-.209.407-.271.152-.063.314-.095.479-.094h4.166c.165 0 .327.031.48.094.151.062.29.155.405.27.116.117.209.255.271.407.063.152.095.314.094.479V3.61M10 6.944L10 18.611M6.25 6.944L6.667 18.611M13.75 6.944L13.333 18.611"
            transform="translate(-833 -686) translate(190 616) translate(643 71)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
